import React, { useEffect, useState } from 'react'
import {
  FormCreditCardValidation
} from './components/organisms/FormCreditCardValidation/FormCreditCardValidation'
import { type TokenizeActionRequest, useTokenizeCard } from './hooks/useTokenizeCard'
import { createRepositoryWithDefaultConfig } from './repositories/Repository'
import ConfigService, { type ENV } from './services/Configs/ConfigsService'
import { type RatierVaultErrorResponse, RatierVaultRepository } from './repositories/RatierVaultRepository'
import { type PaymentCard } from './domain/PaymentCard'
import { type PaymentMethod } from './domain/PaymentMethod'
import { EventEmitterService } from './services/EventEmitterService'
import { hibrydEncrypt } from './cryptography/hibrydEncrypt.js'
import ErrorBoundary from './ErrorBoundary'
import WebFont from 'webfontloader'
import Theme from './Theme'
import "./configs/i18n";
import {DomainEndsWithComBr} from "./utils/domain";


export interface CustomStyle {
  form_title: string,
  form_description: string,
  fontFamily: string
  form_box: string
  input: string
  input_label: string
  input_error: string
  input_error_message: string
  input_icon: string
  input_count: string
  submit_button: string
  submit_button_valid: string,
  box_secured_by_ifood_pago: string
}

interface IPaymentCardBridge {
  cardBridgeIframeId: string
  cardBridgeOrigin: string
  externalVerificationId: string
  paymentMethods: PaymentMethod[]
  deviceId: string
  devicePaymentSecret: string
  accountId: string
  selectedPaymentMethod: string
  customStyle?: CustomStyle,
  additionalData?: Record<string, string>
  isTest: boolean
}

export interface PaymentCardBridgeProps {
  env: ENV
}

function PaymentCardBridge(props: PaymentCardBridgeProps): JSX.Element {
  const configService = new ConfigService(props.env)
  const [eventEmitterService] = useState<any>(new EventEmitterService(window.parent))
  const repository = createRepositoryWithDefaultConfig(props.env)
  const { doTokenize } = useTokenizeCard(new RatierVaultRepository(repository), hibrydEncrypt)
  const [cardBridgeProps, setCardBridgeProps] = useState<IPaymentCardBridge | undefined>(undefined)
  const [customFont, setCustomFont] = useState<string>('')

  useEffect(() => {
    if (customFont === '' || customFont === undefined) return
    WebFont.load({
      google: {
        families: [customFont]
      }
    })
  }, [customFont]);

  useEffect(() => {

    const allowedOrigins = [
      configService.get<string>('simplecheckout.url'),
      configService.get<string>('anotaai.url'),
      configService.get<string>('menu.anotaai.url'),
      configService.get<string>('whitelabel.url'),
      configService.get<string>('whitelabel.url_1'),
      configService.get<string>('whitelabel.url_2'),
      configService.get<string>('whitelabel.url_3'),
      configService.get<string>('whitelabel.url_4'),
      configService.get<string>('whitelabel.url_5'),
      configService.get<string>('whitelabel.url_6'),
      configService.get<string>('whitelabel.url_7'),
      configService.get<string>('whitelabel.url_8'),
      configService.get<string>('whitelabel.url_9')
    ]


    window.addEventListener('message', (e) => {

      if (!allowedOrigins.some(allowedOrigin => allowedOrigin === e.origin) && !DomainEndsWithComBr(e.origin)) return

      eventEmitterService.setTarget(e.origin)

      let domainHost = e.origin

      try {
        domainHost = new URL(e.origin).host
      } catch (error) {
        console.error('invalid host domain');
        domainHost = e.origin
      }

      const setupData = e.data as IPaymentCardBridge
      setupData.additionalData = {
        ...setupData.additionalData,
      }

      if (DomainEndsWithComBr(domainHost)) {
        setupData.additionalData['domain-origin'] = domainHost
      }

      const selectedPaymentMethod = setupData.selectedPaymentMethod

      if (setupData.customStyle !== undefined && setupData.customStyle.fontFamily !== null) {
        setCustomFont(setupData.customStyle.fontFamily)
      }

      setupData.paymentMethods = setupData.paymentMethods.filter((pm) => {
        return pm.type.name === 'ONLINE' && (pm.method.name === 'CREDIT' || pm.method.name === 'DEBIT' || pm.method.name === 'MEAL_VOUCHER') && pm.method.name == selectedPaymentMethod
      })

      setCardBridgeProps(setupData)
    })

    eventEmitterService.sendHandShakeEvent("*", true)

  }, [])

  const onSuccess = (data: PaymentCard): void => {
    if (data.payment_method_id === undefined || cardBridgeProps === undefined) throw new Error('Invalid payment method')
    const selectedPaymentMethod = cardBridgeProps.paymentMethods.find((p: PaymentMethod) => p.id === data.payment_method_id)

    if (selectedPaymentMethod === undefined) throw new Error('Invalid payment method')

    const tokenizeRequest: TokenizeActionRequest = {
      accountId: cardBridgeProps.accountId,
      card: data,
      deviceId: cardBridgeProps.deviceId,
      devicePaymentSecret: cardBridgeProps.devicePaymentSecret,
      paymentMethod: selectedPaymentMethod,
      isTest: cardBridgeProps.isTest ?? false,
      externalVerificationId: cardBridgeProps.externalVerificationId,
      additionalData: cardBridgeProps.additionalData
    }

    eventEmitterService.sendBeforeTokenizeEvent({ ok: true })

    doTokenize(tokenizeRequest)
      .then((result) => {
        if (result === undefined) throw new Error('Unknown error to tokenize card')
        if (data.payment_method_id === undefined) throw new Error('Payment method id not found')
        const selectedPaymentMethod = cardBridgeProps.paymentMethods.find((pm) => pm.id === data.payment_method_id)
        if (selectedPaymentMethod === undefined) throw new Error('Payment method id not found')

        eventEmitterService.sendSuccessEvent({
          card: result.card,
          last4digits: data.number.substring((data.number.length - 4), data.number.length),
          paymentMethodId: data.payment_method_id,
          cvv: (selectedPaymentMethod.method.name === 'MEAL_VOUCHER') ? data.cvv : null
        })
      })
      .catch((err) => {
        const errTyped = err as Response
        errTyped.json()
          .then((responseBody: RatierVaultErrorResponse) => {
            eventEmitterService.sendTokenizeErrorEvent(responseBody['data-error'])
          })
          .catch((err) => { throw new Error(err) })
      })
  }

  return (
    <ErrorBoundary repository={eventEmitterService}>
      <div className="App payment-card-bridge">
        <Theme customStyles={cardBridgeProps?.customStyle}>
          <FormCreditCardValidation onSuccess={onSuccess} availablePaymentMethods={cardBridgeProps?.paymentMethods ?? []} />
        </Theme>
      </div>
    </ErrorBoundary>
  )
}

export default PaymentCardBridge
